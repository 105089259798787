 class AppHeader extends Component {
 	constructor(el) {
 		super(el);
 		this.navOpener = this.$find('[data-nav-opener]');
 //		this.headerOffset = this.$el.offsetTop - window.scrollY;
// 		this.lastScroll = 0;
 		this.addEventListeners();
 	}

// 	openSearch() {
// 		EventBus.getInstance().dispatchEvent('showQuickSearchModal');
// 	}
//
// 	hidingOnScroll() {
// 		const currentScroll = window.pageYOffset;
//
// 		if (currentScroll > this.headerOffset + this.$el.offsetHeight) {
// 			this.$el.classList.add('is-sticked');
// 			this.$el.classList[currentScroll > this.lastScroll ? 'add' : 'remove']('is-up');
// 		} else {
// 			this.$el.classList.remove('is-sticked');
// 			this.$el.classList.remove('is-up');
// 		}
//
// 		this.lastScroll = currentScroll;
// 	}
//
 	addEventListeners() {
 		this.navOpener.addEventListener('click', e => {
 			e.stopPropagation();
 			EventBus.getInstance().dispatchEvent('mainNavigationOpen');
 		});
// 		window.addEventListener('scroll', () => this.hidingOnScroll(), {passive: true});
 	}
}